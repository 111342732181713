import axios from 'axios';
import React, { useState, useEffect }from 'react'
import Title from "../page-title-block/Title"
import "./Profile.css";
import { useNavigate } from 'react-router-dom';


export default function Profile() {

    const navigate = useNavigate();

    const[author, setAuthor] = useState({
      firstName : "",
      lastName: "",
      id: ""
    });
    const [blogs, setBlogs] = useState(); 

    
useEffect(() => {
  const currUser = localStorage.getItem("user");
  if(currUser) {
      const fetchData = async() => {
          try {
              const res = await axios.get(`http://138.234.44.111:8080/getAccount/${currUser}`);
              setAuthor(res.data);

          } catch (error) {

          }
      };
      fetchData();
  } else {
    navigate("/login");
  }
}, []);

useEffect(() => {
  if (author) {
      const fetchPostData = async() => {
          try {
              const res2 = await axios.get(`http://138.234.44.111:8080/getPostsOnAccount/${author.id}`);

              setBlogs(res2.data)
          } catch(err) {

          }
      };
      fetchPostData();
  }
}, [author]);

function handleBlogDelete(id) {
  axios.post(`http://138.234.44.111:8080/posts/${id}/delete`)
      .then(response => {

      })
      .catch(error => {
        console.error('Login failed:', error);
      });
}
    return (
        
      <div>
      <Title title = "My Published Blogs" />
      <div className='author-profile-container'>
      <div className="author-profile">
          <h2 className="author-name">{author.firstName + " " + author.lastName}</h2>
          <p>The "Contribute" feature is still in making. But, we have the basic features developed for now. We are constanly working on making this website better. </p>
        </div>
        
      <ul className="blog-list">
        {blogs && blogs.length > 0 ? (
          blogs.map((blog) => (
          <li key={blog.id} className="blog-item">
            <h4 className="blog-title">{blog.title}</h4>
            <div className="blog-actions">
              <button
                className="blog-delete-btn"
                onClick={() => handleBlogDelete(blog.id)}
              >
                Delete
              </button>
              <button
                className="blog-edit-btn"
                // onClick={() => handleBlogEdit(blog.id)}
              >
                Edit
              </button>
            </div>
          </li> 
        ))): (<h2 className='no-blogs'>No blogs published yet!</h2>)} 
      </ul>
        </div>
        </div>
    )
    
}