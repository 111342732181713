import React, {useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Reset() {

const [user, setUser] = useState({ email: "", otp: "123"});

const navigate = useNavigate();

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://api.uniappguide.com/send-otp', user)
      .then(response => {
        console.log(response);
        navigate("/login/reset/otp", {state : {otp : user.otp, email: user.email}});
      })
      .catch(error => {
        console.error('Login failed:', error);
      });
  };
    return (
        <div>
            <div className="form-box">
            <header className='login__header'>Reset Password</header>
            <div className="input-Field">
            <form onSubmit={handleSubmit} >
                <div className='field input-field'>
                <input type="text" name="email" placeholder="Enter your email" value={user.email} onChange={handleChange} />
                </div>
                <div className="field button-field">
                <button>Send code to email</button>
                </div>
                <div className="form-link">
                <span>Don't have an account? <Link to="/register" className="link signup-link">Create an account</Link></span>
                </div>
            </form>
            </div>
        </div>
        </div>
    )
}