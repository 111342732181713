import React from 'react';
import { FaUserCircle } from "react-icons/fa";
import "./Contributors.css"


export default function Contributors() {

    const contributors = [
        { name: 'Riwaz Poudel', college: 'SIUE' },
        { name: 'Riwaj Khatiwada', college: 'Georgetown University' },
        { name: 'Sauryanshu Khanal', college: 'Grinnel College' },
        { name: 'Ojaswi Dhakal', college: 'Beloit College' },
        { name: 'Atishaya  Maharjan', college: 'University of Manitoba' },
        { name: 'Shreeya Shrestha', college: 'Swinburne University of Technology' },
      ];
    return (
        <div className='block-main-container'>
            <div className='block-desc-wrap'>
                <h2>Contributors</h2>
                
                <em><p>Note: We are currently building a feature where anyone can be an author at this platform and contribute to the community. While we work on the development, please email us at <strong>mail@uniappguide.com</strong> if you are interested in being a contributor for this platform.</p></em><br></br>
                <p>A community led platform. Contributors:</p>
            </div>

            <div className='contributor-callout-wrap'>
            {contributors.map((contributor, index) => (
                <div key={index} className="contributor">
                    <FaUserCircle className='user-icon' />
                    <div className="contributor-info">
                    <p className="contributor-name">{contributor.name}</p>
                    <p className="contributor-college">{contributor.college}</p>
                    </div>
                </div>
            ))}

            </div>
        </div>
    )
}