import React, {useState, useEffect, useContext} from "react";
import PostCard from "../PostCard/PostCard";
import blogPic from "../../assets/blogPic.jpg"
import { Link } from "react-router-dom";
import "./Posts.css"
import Title from "../page-title-block/Title";
import  BlogContext from "../BlogContext";

export default function Posts() {

    const { blogs, loading } = useContext(BlogContext);

    return (
        <div className="posts-main-container">
            <Title title = "Blogs" />
            <div className="posts-container-wrap">
            <div className="posts-wrap">
            {loading ? (<h2>loading..</h2>):blogs.map(post => (
                post.isVerified && !post._ama &&
                <Link key={post.id} to = {`/post/${post.urlSlug}`} className="home_posts"><PostCard key = {post.id} id = {post.id} title = {post.title} metaBody = {post.meta_data} imgSrc = {blogPic} authorFirstName = {post.account.firstName} authorLastName = {post.account.lastName} createdAt = {post.createdAt} urlSlug = {post.urlSlug}/></Link>             
            ))}
            </div>
            </div>
        </div>
    )

} 