    import React, {useEffect, useState, useContext} from 'react';
    import BlogContext from '../../BlogContext';
    import "./Featured.css";
    import { Link } from "react-router-dom";


    export default function Featured() {
        const { blogs, loading } = useContext(BlogContext);

        const firstFour = blogs.slice(0, 4);


        return (
            <div className='block-main-container'>
            <div className='block-desc-wrap'>
                <h2>Featured</h2>
                <p>Most viewed blogs of this week</p>
            </div>
            <div className='featured-block-wrap'>
                {loading ? (<h2>loading..</h2>):firstFour.map(post => (
                    post.isVerified && (
                        <div className='featured-inner-block-wrap' key={post.id}>
                            <div className='featured-content-wrap'>
                                <div className='featured-title-wrap'>
                                    <Link to={`/post/${post.urlSlug}`} className='featured-post-title'><h3>{post.title}</h3></Link>
                                    <p>{post.meta_data}</p>
                                </div>
                            </div>                        
                            </div>
                    )
                ))}
            </div>
        </div> 
        )
    }