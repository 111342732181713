import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from "../../assets/logo.png";
import './Navbar.css';
import axios from 'axios';

export default function Navbar() {
    const [user, setUser] = useState("");

    useEffect(() => {
        const currUser = localStorage.getItem("user");

        if (currUser) {
            const fetchData = async () => {
                const res = await axios.get(`https://api.uniappguide.com/getAccount/${currUser}`);
                setUser(res.data);

        }
        fetchData();
    }
    }, [])

    function goToLogin() {
        window.location.href = "/login";
    }
    function goToCreatePost() {
        window.location.href = "/createPost";
    }

    function handleLogout() {
        setUser("");
        window.localStorage.removeItem('user');
        window.location.reload();
    }

    return (
        <div className='navbar-main'>

        <div className='navbar'>
        <Link to="/"><img src={logo} className="logo" /></Link>
            <div className='navbar_links'>
                <ul className='nav-links'>
                    {/* <li><Link to="/">Home</Link></li> */}
                    <li><Link to="/posts">Blogs</Link></li>
                    <li><Link to="/ama">AMA</Link></li>
                </ul>
            </div>
            {/* <div className='icon'> */}
                {/* {user ? (
                    <button onClick={handleLogout}>Logout</button>
                ) : (
                    <button onClick={goToLogin}>Login</button>
                )} */}
                {/* {!user ? (
                    <Link to = "/login" className='contribute-btn login-btn'>Login</Link>
                ) : <Link to = "/profile">{user.firstName}</Link>}  
                <button className='contribute-btn' onClick={() => goToCreatePost()}>Contribute!</button> */}
               
            {/* </div> */}
           
        </div>
        </div>
    )
}
