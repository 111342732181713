import React, {useState, useEffect} from "react";
import { useLocation } from 'react-router-dom';
import Posts from "../Post/Posts";
import FrontPage from "./FrontPage/frontpage";
import About from "./About/about";
import "./Home.css";
import Team from "./Team/Team";
import Featured from "./Featured/Featured";
import Contributors from "./Contributors/Contributors";



export default function Home() {

    const location = useLocation().pathname;
    const [user, setUser] = useState("");

    useEffect(() => {
        const currUser = localStorage.getItem("user")

        if(currUser) {
            setUser(currUser);
        }
    }, [])

    function Logout() {
        localStorage.removeItem("user")
        setUser("");
        window.location.reload();
    }

    return (
        <div>
            <FrontPage/>
            <Featured />
            <About />
            <Contributors/>
            <Team />
        </div>
        
    )

}